@import "../../Common/_variables";

.claim-number-lookup-form {
  input {
    font-size: 0.85rem;
    background: $gray-200;
    border: 0;
    border-radius: 100px;

    &:focus {
      background: $gray-200;
    }

    &::-ms-clear {
      display: none;
    }
  }

  button {
    border: none;
    background: none;
    margin-left: -3rem;

    img {
      width: 1rem;
      height: 1rem;
    }
  }
}