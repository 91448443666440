@import '../Common/_variables.scss';

.info-field {
  margin-bottom: $spacer;

  address{
    margin-bottom: 0;
  }
}

.select-input {
  .select-input__control {
    border-color: $input-border-color;
    background: $white;
  }

  .select-input-indicator-container {
    color: $gray-500;

    .btn-link {
      color: $gray-500;
    }

    .select-input__dropdown-indicator {
      color: $gray-500;
      padding: 0;
    }
  }

  .select-input__placeholder {
    color: $gray-600;
  }
}