﻿$prune: #B42D34;
$jet: #333;
$red: #ED3841;
$slate: #666;
$platinum: #c9c9c9;

$white: #fff !default;
$gray-100: #f8f9fa !default;
$gray-200: #e9ecef !default;
$gray-300: #dee2e6 !default;
$gray-400: #ced4da !default;
$gray-500: #adb5bd !default;
$gray-600: #868e96 !default;
$gray-700: #495057 !default;
$gray-800: #343a40 !default;
$gray-900: #212529 !default;
$black: #000 !default;

$blue: #20a8d8;
$indigo: #6610f2 !default;
$purple: #6f42c1 !default;
$pink: #e83e8c !default;
$orange: #f8cb00;
$yellow: #ffc107 !default;
$green: #4dbd74;
$teal: #20c997 !default;
$cyan: #63c2de;

$colors: (
  blue: $blue,
  indigo: $indigo,
  purple: $purple,
  pink: $pink,
  red: $red,
  orange: $orange,
  yellow: $yellow,
  green: $green,
  teal: $teal,
  cyan: $cyan,
  white: $white,
  gray: $gray-600,
  gray-dark: $gray-800
);

$theme-colors: (
  primary: $blue,
  secondary: $gray-300,
  success: $green,
  info: $cyan,
  warning: $yellow,
  danger: $red,
  light: $gray-100,
  dark: $jet
);

$enable-rounded: true;
$enable-shadows: false;
$spacer: 1rem;

$input-focus-box-shadow: none;
$input-group-addon-border-color: $platinum;
$input-border-color: $platinum;

$form-feedback-valid-color: $platinum;
$form-feedback-invalid-color: $platinum;


$font-size-base: 0.8rem;
$font-family-sans-serif: "Arimo", "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
$header-font-family: "Poppins", Arial, sans-serif;


$list-group-border-color: $platinum;
$list-group-active-color: $prune;
$list-group-active-bg: $white;
$list-group-border-width: 0;


$hover-bg-color: #EBF6FF;